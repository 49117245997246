<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage buyers</div>
      </portal>
      
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :to="{ name: 'CreateBuyer' }">
        Create buyer
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
      
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        :expanded.sync="expanded"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        show-expand
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.name="{ item }">
          <div class="d-flex align-center">
            <span class="font-normal text-no-wrap">{{ item.name }}</span>
            <TooltipAction bottom message="Buyer doesn't receive calls right now" :maxWidth="180" v-if="dataWarningStatus[item.slug]">
              <template #activator="{ on }">
                <v-icon v-on="on" class="pl-5" color="yellow">
                  $systemIcon
                </v-icon>
              </template>
            </TooltipAction>
          </div>
        </template>
        
<!--        <template #item.pause_targets="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['pause_targets']"-->
<!--            @input="changeOption(item, 'pause_targets')"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->
<!--        -->
<!--        <template #item.set_concurrency="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['set_concurrency']"-->
<!--            @input="changeOption(item, 'set_concurrency')"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->
<!--        -->
<!--        <template #item.dispute_conversion="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['dispute_conversion']"-->
<!--            @input="changeOption(item, 'dispute_conversion')"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->
<!--        -->
<!--        <template #item.set_capacity="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['set_capacity']"-->
<!--            @input="changeOption(item, 'set_capacity')"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->

<!--        <template #item.hangup_calls="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['hangup_calls']"-->
<!--            @input="changeOption(item, 'hangup_calls')"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->

<!--        <template #item.block_numbers="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['block_numbers']"-->
<!--            @input="changeOption(item, 'block_numbers')"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->
  
        <template #item.hourly="{ item }">
          <AnimationChangeCounter
            :counter="dataCounters[item.slug] ? dataCounters[item.slug].hourly : 0"
            :cap="item['hourly_revenue_cap']"
            isCurrency
          />
        </template>
  
        <template #item.daily="{ item }">
          <AnimationChangeCounter
            :counter="dataCounters[item.slug] ? dataCounters[item.slug].daily : 0"
            :cap="item['daily_revenue_cap']"
            isCurrency
          />
        </template>
  
        <template #item.monthly="{ item }">
          <AnimationChangeCounter
            :counter="dataCounters[item.slug] ? dataCounters[item.slug].monthly : 0"
            :cap="item['monthly_revenue_cap']"
            isCurrency
          />
        </template>
  
        <template #item.global="{ item }">
          <AnimationChangeCounter
            :counter="dataCounters[item.slug] ? dataCounters[item.slug].global : 0"
            :cap="item['global_revenue_cap']"
            isCurrency
          />
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Invite">
            <template #activator>
              <v-icon class="mr-6" @click="openInviteUser(item)">$userPlusIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Edit">
            <template #activator>
              <v-icon class="mr-6" @click="openEditBuyer(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>
  
          <TooltipAction bottom message="reset caps">
            <template #activator>
              <v-icon class="mr-6" @click="openResetCapsBuyer(item)">$resetIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon @click="openDeleteBuyerDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
        
        <template #item.data-table-expand="{ expand, isExpanded, item }">
          <v-icon @click="expand(!isExpanded)" v-if="!item.private">$cornerDown</v-icon>
        </template>
        
        <template #expanded-item="{ headers, item }">
          <td class="cardChildBg pa-0" :colspan="headers.length">
            <v-expand-transition>
              <v-data-table
                class="elevation-0 cardChildBg"
                :headers="headersChild"
                :items="item.invitations"
                item-key="slug"
                outlined
                hide-default-footer
                hide-default-header
                disable-pagination
                :mobile-breakpoint="0"
              >
                <template #item.first_name="{ item }">
                  <span class="childNameColor--text pl-26">{{ item['first_name'] }}</span>
                </template>
                
                <template #item.last_name="{ item }">
                  <span class="childNameColor--text">{{ item['last_name'] }}</span>
                </template>
                
                <template #item.email="{ item }">
                  <span class="childEmailColor--text">{{ item.email }}</span>
                </template>
                
                <template #item.accepted="{ item }">
                  <span v-if="item.accepted" class="acceptedColor--text">Accepted</span>
                  <span v-else class="pendingColor--text">Pending</span>
                </template>
                
                <template #item.actions="{ item }">
                  <div class="d-flex justify-end">
                    <TooltipAction bottom message="Resend" v-if="!item.accepted">
                      <template #activator>
                        <v-icon class="mr-6" @click="openResendInvite(item)">$resendIcon</v-icon>
                      </template>
                    </TooltipAction>
                    
                    <TooltipAction bottom message="Delete">
                      <template #activator>
                        <v-icon @click="openDeleteUser(item)">$deleteIcon</v-icon>
                      </template>
                    </TooltipAction>
                  </div>
                </template>
              </v-data-table>
            </v-expand-transition>
          </td>
        </template>
      </v-data-table>
      
<!--      <v-pagination-->
<!--        class="paginationBody text-center pt-6"-->
<!--        color="paginationBg"-->
<!--        :value="dataPagination.page"-->
<!--        :length="dataPagination.lastPage"-->
<!--        :page="dataPagination.page"-->
<!--        total-visible="8"-->
<!--        :disabled="loading"-->
<!--        prev-icon="$prevIcon"-->
<!--        next-icon="$nextIcon"-->
<!--        @input="changePage"-->
<!--      />-->

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="actionSuccess"
    />
    
    <DialogBuyersInvite
      v-model="inviteDialog"
      :buyerSlug="dialogBuyerSlug"
      @actionSuccess="inviteSuccess"
    />
    
    <DialogResetCaps
      v-model="resetCapsDialog"
      :itemId="buyerSlugForResetCaps"
      forWhomAction="Buyer"
      @actionSuccess="setCountersInterval"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import SwitchSm from '@/components/controls/SwitchSm'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogBuyersInvite from '@/components/dialog/DialogBuyersInvite'
import DialogResetCaps from '@/components/dialog/DialogResetCaps'
import AnimationChangeCounter from '@/components/animation/AnimationChangeCounter'
import TablePagination from '@/components/pagination/TablePagination'

import { getBuyers, getBuyerCounters, updateBuyer, deleteBuyer } from '@/api/buyer-routes'
import { resendInvite, deleteInvitedUser } from '@/api/user-routes'
import { getOnPage, changeItemsAfterUpdate, parsePaginationData } from '@/helper/app-helper'

export default {
  name: 'ManageBuyers',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, SwitchSm, TooltipAction, DialogConfirm, DialogBuyersInvite, DialogResetCaps, AnimationChangeCounter, TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    dialog: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1
    },
    options: {},
    headers: [
      { text: '', value: 'data-table-expand', sortable: false, align: 'left' },
      { text: 'Company Name', value: 'name', sortId: 'byName' },
      // { text: 'Pause Destinations', value: 'pause_targets', align: 'center',sortId: 'byPauseTarget' },
      // { text: 'Set Concurrency', value: 'set_concurrency', align: 'center', sortId: 'bySetConcurrency' },
      // { text: 'Dispute Conversions', value: 'dispute_conversion', align: 'center', sortId: 'byDisputeConversion' },
      // { text: 'Set Caps', value: 'set_capacity', align: 'center', sortId: 'bySetCapacity' },
      // { text: 'Hang Up', value: 'hangup_calls', align: 'center', sortId: 'byHangUp' },
      // { text: 'Block Numbers', value: 'block_numbers', align: 'center', sortId: 'byBlockNumbers' },
      { text: 'HOURLY', value: 'hourly', align: 'center', sortable: false },
      { text: 'DAILY', value: 'daily', align: 'center', sortable: false },
      { text: 'MONTHLY', value: 'monthly', align: 'center', sortable: false },
      { text: 'GLOBAL', value: 'global', align: 'center', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: 180, align: 'right' },
    ],
    headersChild: [
      { text: 'First Name', value: 'first_name', class: 'text-no-wrap' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Email', value: 'email' },
      { text: 'Status', value: 'accepted' },
      { text: 'Actions', value: 'actions', align: 'right' },
    ],
    buyerCountersInterval: null,
    dataCounters: {},
    dataWarningStatus: {},
    expanded: [],
    inviteDialog: false,
    dialogBuyerSlug: null,
    resetCapsDialog: false,
    buyerSlugForResetCaps: null,
    confirmDialog: false,
    actionName: null,
    selectedItem: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
    search: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadBuyers() {
      this.loading = true

      const { success, payload, message } = await getBuyers({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        // this.dataPagination.page = payload['current_page']
        // this.dataPagination.lastPage = payload['last_page']
        this.items = payload.data
        this.setCountersInterval()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    setCountersInterval() {
      clearInterval(this.buyerCountersInterval)
      this.loadCounters()
      this.buyerCountersInterval = setInterval(() => {
        this.loadCounters()
      }, 15000)
    },
    async loadCounters() {
      const params = {
        buyers: this.items.map(buyer => buyer.slug)
      }

      if (params.buyers.length) {
        const { success, payload } = await getBuyerCounters(params)

        if (success) {
          this.parseDataCounters(payload)
        }
      }
    },
    parseDataCounters(counters) {
      if (Array.isArray(counters)) {
        counters.forEach(item => {
          this.dataCounters[item.slug] = item['capsCounter']
          this.dataWarningStatus[item.slug] = item['warningStatus']
        })

        this.items = this.items.slice()
      }
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadBuyers()
      }
      this.$vuetify.goTo(0)
    },
    changeOption(buyer, option) {
      const formData = {
        name: buyer.name,
        'timezone_id': buyer['timezone_id'],
        'pause_targets': buyer['pause_targets'],
        'set_concurrency': buyer['set_concurrency'],
        'dispute_conversion': buyer['dispute_conversion'],
        'set_capacity': buyer['set_capacity'],
        'hangup_calls': buyer['hangup_calls'],
        'global_revenue_cap': buyer['global_revenue_cap'],
        'monthly_revenue_cap': buyer['monthly_revenue_cap'],
        'daily_revenue_cap': buyer['daily_revenue_cap'],
        'hourly_revenue_cap': buyer['hourly_revenue_cap'],
      }
      formData[option] = !!buyer[option] ? 0 : 1
      this.updateBuyer({
        buyerId: buyer.slug,
        formData
      })
    },
    async updateBuyer({ buyerId, formData }) {
      this.loadingStatus = true

      const { success, payload, message } = await updateBuyer({ buyerId, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.items = changeItemsAfterUpdate(this.items, payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    openEditBuyer(buyer) {
      this.$router.push({ name: 'DetailBuyer', params: { slug: buyer.slug }})
    },
    openResetCapsBuyer(buyer) {
      this.resetCapsDialog = true
      this.buyerSlugForResetCaps = buyer.slug
    },
    openInviteUser(buyer) {
      this.dialogBuyerSlug = buyer.slug
      this.inviteDialog = true
    },
    inviteSuccess(payout) {
      this.loadBuyers()
    },
    actionSuccess() {
      this[this.actionName]()
    },
    openResendInvite(user) {
      this.actionName = 'resendInvite'
      this.selectedItem = user
      this.dialogTitle = 'Resend invite'
      this.dialogSubTitle = `${user.email}`
      this.dialogMessage = 'Are you sure you want to resend the invite to this user?'
      this.confirmDialog = true
    },
    async resendInvite() {
      this.loadingAction = true

      const { success, message } = await resendInvite(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    openDeleteUser(user) {
      this.actionName = 'deleteInvitedUser'
      this.dialogTitle = 'Delete user'
      this.selectedItem = user
      this.dialogSubTitle = `${user['first_name']} ${user['last_name']}`
      this.dialogMessage = 'Are you sure you want to delete this user?'
      this.confirmDialog = true
    },
    async deleteInvitedUser() {
      this.loadingAction = true

      const { success, message } = await deleteInvitedUser(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.loadBuyers()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    openDeleteBuyerDialog(buyer) {
      this.actionName = 'deleteBuyer'
      this.selectedItem = buyer
      this.dialogTitle = 'Delete buyer'
      this.dialogSubTitle = `${buyer.name}`
      this.dialogMessage = 'Are you sure you want to delete this buyer?'
      this.confirmDialog = true
    },
    async deleteBuyer() {
      this.loadingAction = true

      const { success, message } = await deleteBuyer(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadBuyers()
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadBuyers()
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadBuyers()
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.buyerCountersInterval)
  }
}
</script>
